$primary: #ff9f43;
$secondary: #03B22F;
$info: #3b63ff;

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800;900&display=swap');
@import '~bootstrap/scss/bootstrap.scss';

body,
html {
  font-family: 'Raleway', sans-serif;
}

a {
  text-transform: uppercase;
}

.btn {
  text-transform: capitalize !important;
  font-weight: 500;
  padding: 0.375rem 1.5rem;
  border-radius: .358rem;
}

.btn.active,
.btn.focus,
.btn:active,
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.nav-item .btn.btn-primary {
  background: linear-gradient(118deg, #ff9f43, rgba(255, 159, 67, .7));
  box-shadow: 0 0 6px 1px rgb(255 159 67 / 60%);
  border-radius: 5%;
}

.nav-item .btn,
.nav-item .nav-link,
.navbar-nav .nav-link {
  text-transform: capitalize !important;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
}

.btn-primary {
  color: #5e5873;
}

.btn-secondary {
  color: white !important;
}


.btn-primary:hover:not(.disabled):not(:disabled),
.btn-outline-primary:hover:not(.disabled):not(:disabled),
.btn-primary:focus:not(.disabled):not(:disabled),
.btn-outline-primary:focus:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #ff9f43 !important;
  color: white;
}

.btn-dark:hover:not(.disabled):not(:disabled),
.btn-outline-dark:hover:not(.disabled):not(:disabled),
.btn-dark:focus:not(.disabled):not(:disabled),
.btn-outline-dark:focus:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #212529 !important;
  color: white;
}

.btn-secondary:hover:not(.disabled):not(:disabled),
.btn-outline-secondary:hover:not(.disabled):not(:disabled),
.btn-secondary:focus:not(.disabled):not(:disabled),
.btn-outline-secondary:focus:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #03B22F !important;
  color: white;
}

.btn-danger:hover:not(.disabled):not(:disabled),
.btn-outline-danger:hover:not(.disabled):not(:disabled),
.btn-secondary:focus:not(.disabled):not(:disabled),
.btn-outline-danger:focus:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #dc3545 !important;
  color: white;
}

.btn-outline-primary:focus:not(.disabled):not(:disabled),
.btn-outline-primary:active:not(.disabled):not(:disabled) {
  background-color: #ff9f43 !important;
  // color: #ff9f43 !important;
}

.btn-outline-dark:focus:not(.disabled):not(:disabled),
.btn-outline-dark:active:not(.disabled):not(:disabled) {
  background-color: #212529 !important;
  // color: #ff9f43 !important;
}

.btn-outline-secondary:focus:not(.disabled):not(:disabled),
.btn-outline-secondary:active:not(.disabled):not(:disabled) {
  background-color: #03B22F !important;
}

.btn-outline-danger:focus:not(.disabled):not(:disabled),
.btn-outline-danger:active:not(.disabled):not(:disabled) {
  background-color: #dc3545 !important;
}

.btn-check:focus+.btn,
.btn:focus:not(.shadow-none) {
  box-shadow: 0 8px 25px -8px #ff9f43 !important;
}

.input-group:focus-within .form-control,
.input-group:focus-within .input-group-text,
.form-control:focus {
  border-color: #ff9f43;
  box-shadow: none;
}

.was-validated .form-control:not(.disabled):not([readonly]):invalid:focus,
.form-control:not(.disabled):not([readonly]).is-invalid:focus {
  box-shadow: 0 3px 10px 0 rgb(220 53 69 / 25%);
}

.input-group:not(.disabled):not([readonly]):focus-within,
.form-control:not(.disabled):not([readonly]):focus.is-valid,
.form-control:not(.disabled):not([readonly]):focus:valid {
  box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
  border-radius: 0.357rem;
}

input:not([readonly]):not(.disabled):focus::placeholder {
  padding-left: .4rem;
}

input:disabled,
input.disabled,
input[readonly] {
  border: none !important;
  box-shadow: none !important;
}

::placeholder {
  font-size: small;
  transition: all .15s;
}

.form-control:not(.form-control-sm) {
  padding: .438rem 1rem;
}

.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: 0 !important;
}

@media (min-width: 1600px) {
  .container-xxl-custom {
    max-width: 1520px;
  }
}

@media (min-width: 1900px) {
  .container-xxl-custom {
    max-width: 1820px;
  }
}

.fixed-nav-margin {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.line-height-1-45 {
  line-height: 1.45 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


// Custom file button 

input[type=file].custom-file-upload:not(.disabled):not(:disabled)::file-selector-button {
  margin: 0 .5rem 0 0 !important;
  background-color: #ff9f43 !important;
  border: 1px solid transparent !important;
  color: white;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

input[type=file].custom-file-upload:not(.disabled):not(:disabled):hover::file-selector-button,
input[type=file].custom-file-upload:not(.disabled):not(:disabled):focus::file-selector-button,
input[type=file].custom-file-upload:not(.disabled):not(:disabled):active::file-selector-button {
  background-color: #ff9f43;
  box-shadow: 0 8px 25px -8px #ff9f43 !important;
  color: white;
}


input[type=file].custom-file-upload:not(.disabled):not(:disabled)::-ms-browse {
  margin: 0 .5rem 0 0 !important;
  background-color: #ff9f43 !important;
  border: 1px solid transparent !important;
  color: white;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

input[type=file].custom-file-upload:not(.disabled):not(:disabled):hover::-ms-browse,
input[type=file].custom-file-upload:not(.disabled):not(:disabled):focus::-ms-browse,
input[type=file].custom-file-upload:not(.disabled):not(:disabled):active::-ms-browse {
  background-color: #ff9f43;
  box-shadow: 0 8px 25px -8px #ff9f43 !important;
  color: white;
}


input[type=file].custom-file-upload:not(.disabled):not(:disabled)::-webkit-file-upload-button {
  margin: 0 .5rem 0 0 !important;
  background-color: #ff9f43 !important;
  border: 1px solid transparent !important;
  color: white;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

input[type=file].custom-file-upload:not(.disabled):not(:disabled):hover::-webkit-file-upload-button,
input[type=file].custom-file-upload:not(.disabled):not(:disabled):focus::-webkit-file-upload-button,
input[type=file].custom-file-upload:not(.disabled):not(:disabled):active::-webkit-file-upload-button {
  background-color: #ff9f43;
  box-shadow: 0 8px 25px -8px #ff9f43 !important;
  color: white;
}

.toast-container-overlay {
  position: fixed !important;
  z-index: 9999 !important;
}

.sticky-bottom {
  position: sticky;
  background-color: inherit !important;
  z-index: 1020;
  bottom: 0;
}

@media(max-width: 600px) {

  .table-no-wrap th,
  .table-no-wrap td {
    white-space: nowrap !important;
  }
}

.table-no-wrap th {
  white-space: nowrap !important;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

@media (min-width: 992px) {

  .swal2-popup.swal-size-lg,
  .swal2-popup.swal-size-xl {
    max-width: 800px !important;
  }
}

@media (min-width: 1200px) {
  .swal2-popup.swal-size-xl {
    max-width: 1140px !important;
  }
}

.uploaded-file-icon {
  width: 48px;
  height: 48px;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 75%;
}

#dropdown-connections::after {
  display: none !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.react-multiple-carousel__arrow {
  background-color: transparent !important;
}

.react-multiple-carousel__arrow::before {
  color: #ff9f43 !important;
  font-size: 25px !important;
  font-weight: bold !important;
}

.carousel-height-300 {
  height: 300px !important;
}

@media print {
  body {
    zoom: 80%;
    margin: 0;
    padding: 0;
  }

  @page {
    size: auto;
    margin: 10mm 0 20mm 0;
  }

  table {
    page-break-inside: auto
  }

  tr {
    page-break-inside: avoid;
    page-break-after: auto
  }

  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group;
  }

  div {
    page-break-inside: avoid;
  }

  /* This is the key */
}

.required:after {
  color: #c00;
  content: " *";
  font-family: serif;
}

.dropdown-arrow-none::after {
  content: none !important;
  border: none !important;
}